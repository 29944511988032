import React from 'react';
import { ForgotPassword, FormBody, FormInput, Password, PeekerBoxyStyle } from './../../styles';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import { LOGIN_FIELDS } from 'shared/screens/Login/login-validations';
import { TextInputBootstrap } from './../../../../components/TextInput';
import BoxyLayoutTextInputsWrapper from '../../../../components/TextInput/BoxyLayoutTextInputsWrapper';
import PasswordInput from '../../../../components/PasswordInput';

function BoxyLayout({ values, errors, onChangeHandler, handleBlur, openForgotPassword }) {
  return (
    <FormBody>
      <FormInput className="form-input-group">
        <BoxyLayoutTextInputsWrapper
          checkErrorLength
          icon={assetSource(ASSET_KEY.INPUT_EMAIL_ICON_REGULAR)}
          iconError={assetSource(ASSET_KEY.INPUT_EMAIL_ICON_ERROR)}
          error={errors?.email}
        >
          <TextInputBootstrap
            icon
            boxErrorLayout
            error={errors?.email}
            placeholder={getText(TEXT_KEY.EMAIL)}
            autoCapitalize="none"
            value={values.email}
            onChange={e => onChangeHandler(LOGIN_FIELDS.EMAIL, e.target.value, true)}
            onBlur={handleBlur(LOGIN_FIELDS.EMAIL)}
          />
        </BoxyLayoutTextInputsWrapper>
      </FormInput>
      <Password>
        <FormInput className="form-input-group">
          <BoxyLayoutTextInputsWrapper
            checkErrorLength
            icon={assetSource(ASSET_KEY.INPUT_PASSWORD_ICON_REGULAR)}
            iconError={assetSource(ASSET_KEY.INPUT_PASSWORD_ICON_ERROR)}
            error={errors?.password}
          >
            <PasswordInput
              icon
              boxErrorLayout
              error={errors?.password}
              placeholder={getText(TEXT_KEY.PASSWORD)}
              value={values.password}
              onChange={e => onChangeHandler(LOGIN_FIELDS.PASSWORD, e.target.value)}
              onBlur={handleBlur(LOGIN_FIELDS.PASSWORD)}
              secureTextEntry={true}
              customClassName="transparentInput"
              customPeekerStyle={PeekerBoxyStyle}
            />
          </BoxyLayoutTextInputsWrapper>
        </FormInput>
        <ForgotPassword onClick={openForgotPassword}>{getText(TEXT_KEY.FORGOT_PASSWORD)}</ForgotPassword>
      </Password>
    </FormBody>
  );
}

export default BoxyLayout;

